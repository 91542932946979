import {
  Typography,
  FormGroup,
  FormControlLabel,
  FormControl,
  Select,
  Box,
  Grid,
  Button,
  Checkbox,
} from "@mui/material";
import React, { useState } from "react";
import PropTypes from "prop-types";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
// import DoneSharpIcon from "@mui/icons-material/DoneSharp";
import CheckBoxOutlineBlankSharpIcon from "@mui/icons-material/CheckBoxOutlineBlankSharp";
import CheckBoxSharpIcon from "@mui/icons-material/CheckBoxSharp";
// import { getRequestTypesAssets } from "api/apiMethods";

function DropdownOptionsRequestTypes(props) {
  const {
    selectedRequestTypes,
    setSelectedRequestTypes,
    filterNames,
    setFilterNames,
    isOpenRequest,
    handleClickRequest,
    requestDetails,
  } = props;

  const os = navigator.userAgent;
  let system = "window";
  if (os.search("Mac") !== -1) {
    system = "mac";
  }

  // const [requestDetails, setRequestDetails] = useState(null);
  const [filternamesRequesType, setfilternamesRequest] = useState([]);

  const toggleSelectionCheckbox = (event) => {
    const filterlist = [...filternamesRequesType];
    if (event.target.checked) {
      setSelectedRequestTypes((prevState) => {
        return prevState.concat(event.target.value);
      });
      setFilterNames((prevState) => {
        return prevState.concat(event.target.value);
      });
      filterlist.push(event.target.value);
      setfilternamesRequest(filterlist);
    } else {
      filterlist.filter((item) => item !== event.target.value);
      setfilternamesRequest(filterlist);
      setSelectedRequestTypes((prevState) => {
        return prevState.filter((a) => a !== event.target.value);
      });
      setFilterNames((prevState) => {
        return prevState.filter((a) => a !== event.target.value);
      });
    }
  };

  const handleClearAll = () => {
    setSelectedRequestTypes([]);
    const filterNameList = [...filterNames];
    const filterListName = filterNameList.filter((item) => {
      const isexistItem = filternamesRequesType.find((items) => items === item);
      if (isexistItem) {
        return item !== isexistItem;
      }
      return item;
    });
    setFilterNames(filterListName);
    sessionStorage.removeItem("selectedRequestTypesDetail");
  };

  // useEffect(() => {
  //   getRequestTypesAssets().then((response) => {
  //     if (response !== false) {
  //       setRequestDetails(response);
  //     }
  //   });
  // }, []);
  return (
    <FormControl variant="standard" sx={isOpenRequest ? { width: 157 } : { width: 127 }}>
      <Typography
        sx={{
          margin: "0px",
          fontWeight: "400",
          lineHeight: "1.5",
          letterSpacing: "0.00938em",
          fontSize: "14px",
          cursor: "pointer",
          fontFamily: "GothamBook",
          color: "#4a4a4a",
          display: "flex",
          paddingLeft: isOpenRequest && "15px",
        }}
        id="demo-simple-select-standard-label">
        REQUEST TYPE
      </Typography>
      <Select
        labelId="demo-multiple-checkbox-label"
        id="demo-multiple-checkbox"
        multiple
        onOpen={handleClickRequest}
        onClose={handleClickRequest}
        sx={{
          // bgcolor: "red",
          color: "white",
          ".MuiSvgIcon-root ": {
            fill: "#216fb4 !important",
            width: isOpenRequest ? 60 : 25,
          },
          ".MuiSelect-select": {
            background: "#ffffff00",
          },
        }}
        className={isOpenRequest ? "p-request-pop-over__request-type-box__request-container-head" : ""}
        MenuProps={{
          classes: { paper: "reqtypePaper" },
          PaperProps: {
            sx: {
              // bgcolor: "pink",
              "& .MuiList-root": {
                paddingTop: 0,
                background: "rgba(250, 244, 244, 0) !important",
              },
              // width: 500,
              position: "absolute",
              left: system === "mac" ? "54.5% !important" : "48.5% !important",
            },
          },
        }}
        IconComponent={ExpandMoreIcon}
        style={
          isOpenRequest
            ? { position: "absolute", top: -7, left: "0px", width: "175px", paddingRight: "35px" }
            : { position: "absolute", top: -7, left: "0px", width: "135px" }
        }
        disableUnderline
        value={selectedRequestTypes}
        renderValue={() => {}}
        variant="standard">
        <Box className="p-request-pop-over__request-type-box">
          <Grid container className="p-request-pop-over__request-type-box__request-container-body">
            <Grid item md={7} />
            <Grid item md={4.1}>
              <Button
                onClick={handleClearAll}
                sx={{
                  borderRadius: "0px",
                  color: "#216fb4",
                  fontFamily: "GothamBook",
                  fontSize: "14px",
                  textTransform: "capitalize",
                }}>
                Clear All
              </Button>
            </Grid>

            <Grid
              md={12}
              mb={3}
              className="p-request-pop-over__request-type-box__request-scroll-body">
              <FormGroup>
                {requestDetails !== null &&
                  requestDetails.map((requestTypes) => (
                    <FormControlLabel
                      classes={{
                        label: "p-request-pop-over__request-type-box__request-type-text",
                      }}
                      sx={{
                        pt: 0.8,
                        pl: 2.75,
                      }}
                      control={
                        <Checkbox
                          onChange={toggleSelectionCheckbox}
                          value={requestTypes.internal_name}
                          checked={selectedRequestTypes.includes(requestTypes.internal_name)}
                          icon={
                            <CheckBoxOutlineBlankSharpIcon
                              sx={{
                                width: "16px",
                                height: "16px",
                                color: "#216FB4",
                              }}
                            />
                          }
                          checkedIcon={
                            <CheckBoxSharpIcon
                              sx={{
                                width: "16px",
                                height: "16px",
                                color: "#216FB4",
                              }}
                            />
                          }
                        />
                      }
                      label={requestTypes.name}
                    />
                  ))}
              </FormGroup>
            </Grid>
          </Grid>
        </Box>
      </Select>
    </FormControl>
  );
}

DropdownOptionsRequestTypes.propTypes = {
  setFilterNames: PropTypes.func.isRequired,
  filterNames: PropTypes.arrayOf.isRequired,
  selectedRequestTypes: PropTypes.arrayOf.isRequired,
  setSelectedRequestTypes: PropTypes.func.isRequired,
  handleClickRequest: PropTypes.func.isRequired,
  isOpenRequest: PropTypes.bool.isRequired,
  requestDetails: PropTypes.arrayOf.isRequired,
};

export default DropdownOptionsRequestTypes;

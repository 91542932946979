import React, { useEffect, useState } from "react";
import { Navigate, useLocation } from "react-router-dom";
import { useOktaAuth } from "@okta/okta-react";
import { CircularProgress } from "@mui/material";

import { API_URL } from "../../api/endpoints";

function LoginLanding() {
  const { authState, oktaAuth } = useOktaAuth();
  const [userFound, setUserFound] = useState(false);
  const location = useLocation();
  const randomBgClass = `main-login-landing__background-container-${Math.round(
    Math.random() * 4
  )} profile`;

  useEffect(() => {
    if (authState && authState.isAuthenticated && localStorage.getItem("user_id") === null) {
      const { idToken, accessToken } = JSON.parse(localStorage.getItem("okta-token-storage"));

      if (!idToken || !idToken?.claims) {
        // Navigate to home if idToken is missing
        <Navigate to="/" />;
        return; // Ensure an early return here to avoid further execution
      }

      fetch(`${API_URL}/api/rest-endpoint/get/user/${idToken?.claims?.email}`, {
        headers: { Authorization: `Bearer ${accessToken?.accessToken}` },
      })
        .then((res) => res.json())
        .then((userInfo) => {
          localStorage.setItem("user_id", userInfo[0]?.id);
          localStorage.setItem("user_roles", userInfo[0]?.roles);
          setUserFound(true);
        })
        .catch((error) => console.error("Failed to fetch user data:", error));

      if (sessionStorage.getItem("profile") === null) {
        oktaAuth
          .getUser()
          .then((user) => {
            console.log("User object:", user);

            // Directly access `user` properties instead of `user.profile`
            if (!user) {
              console.error("User is missing or undefined");
              return; // Exit early if user is missing
            }

            // Check if the `partner2` key is present and handle it accordingly
            const partners = user.partner2 || [];

            if (partners.length === 0) {
              // No partner2 keys found, set `partners` as an empty array
              sessionStorage.setItem("partners", "");
            } else {
              // Process the `partners` array as needed
              const partnersFilters = partners.map((partner) => {
                if (Array.isArray(partner)) {
                  return partner.join(":");
                }
                return partner;
              });

              const uniquePartners = [...new Set(partnersFilters)];
              sessionStorage.setItem("partners", JSON.stringify(uniquePartners));
            }

            // Store the entire user object as `profile`
            sessionStorage.setItem("profile", JSON.stringify(user));

            // Log sessionStorage state for debugging
            console.log("Session Storage state after setting profile and partners:", {
              profile: sessionStorage.getItem("profile"),
              partners: sessionStorage.getItem("partners"),
            });
          })
          .catch((error) => {
            console.error("Failed to fetch Okta user profile:", error);
          });
      }
    } else {
      setUserFound(true);
    }
  }, [authState, oktaAuth]);

  const login = async () => {
    await oktaAuth.signInWithRedirect();
  };

  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    const issuer = queryParams.get("iss");
    if (issuer) {
      login();
    }
  }, [location.search]);

  if (!authState) {
    return (
      <CircularProgress
        size={100}
        sx={{
          color: "#262262",
          position: "absolute",
          top: "50%",
          left: "50%",
          marginTop: "-12px",
          marginLeft: "-12px",
        }}
      />
    );
  }

  if (authState && !authState.isAuthenticated && !authState.isPending) {
    return (
      <div className="main-login-landing">
        <div className={`${randomBgClass} main-login-landing__inner-main-container`}>
          <div className="main-login-landing__login-box-container">
            <section className="main-login-landing__main-login-card">
              <img
                src="/static/media/AssetTrackerLogo.3e59a2808cdcace3531e.webp"
                alt="Asset Tracker Logo"
              />
              <button className="main-login-landing__login-btn" type="button" onClick={login}>
                Log in
              </button>
            </section>
          </div>
        </div>
      </div>
    );
  }

  if (!userFound) {
    return (
      <CircularProgress
        size={100}
        sx={{
          color: "#262262",
          position: "absolute",
          top: "50%",
          left: "50%",
          marginTop: "-12px",
          marginLeft: "-12px",
        }}
      />
    );
  }

  return <Navigate to="/home" />;
}

export default LoginLanding;

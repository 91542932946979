/* eslint-disable react/prop-types */
import { Box, Grid, Typography } from "@mui/material";
import React from "react";
import "style/components/Extras/footer.scss";

function Footer() {

  const currentYear = new Date().getFullYear(); // Get the current year dynamically

  return (
    <Grid container alignContent="center" mb={1.2}>
      <Grid item md={12} mt={1.75} alignContent="center" alignItems="center">
        <Typography
          variant="body"
          component="div"
          className="footer-center-active__All-of-Us-and-the-Al"
          // : "footer-center-active__All-of-Us-and-the-Al-not-center"
        >
          <Box sx={{ fontStyle: "italic" }} component="span">
            All of Us{" "}
          </Box>
          and the{" "}
          <Box sx={{ fontStyle: "italic" }} component="span">
            All of Us{" "}
          </Box>
          logo are registered service marks of the U.S. Department of Health and Human Services.
        </Typography>
        <Typography
          component="div"
          mt={1}
          variant="body"
          className="footer-center-active__All-of-Us-and-the-Al-text-style-4">
          <a
            className="footer-center-active__footer-mail-link"
            href="mailto:at.support@allofuscomms.org">
            at.support@allofuscomms.org
          </a>{" "}
        </Typography>

        <Typography
          component="div"
          mt={1}
          variant="body"
          className="footer-center-active__All-of-Us-and-the-Al text-style-4">
          Copyright © {currentYear}{" "}
          <a href="https://www.allofuscomms.org/terms" target="_blank" rel="noreferrer">
            <Typography
              component="span"
              variant="body"
              className="footer-center-active__All-of-Us-and-the-Al-text-style-4">
              Terms and Conditions
            </Typography>
          </a>
        </Typography>
      </Grid>
    </Grid>
  );
}
export default Footer;

import React from "react";
import {
  Container,
  Box,
  Grid,
  Card,
  CardMedia,
  Divider,
  CircularProgress,
  ThemeProvider,
  createTheme,
} from "@mui/material";

// Local Imports
import Heading from "components/HeadingComponent/Heading";
import { getStartedData } from "api/apiMethods";
import { useQuery } from "react-query";
import Footer from "components/Footer/Footer";
import Description from "components/DescriptionComponent/descriptionComponent";
import GetStartedDetailDescription from "components/GetStartedDetailDescription/GetStartedDetailDescription";
import GetStartedFAQs from "components/GetStartedFAQs/GetStartedFAQs";
import parser from "html-react-parser";

const theme = createTheme({
  typography: {
    fontFamily: ["GothamBook"].join(","),
    body1: {
      fontSize: 14,
      lineHeight: 1.57,
      fontWeight: 400,
    },
  },
});

const playVid = (vid) => {
    if (vid.controls === false) {
      vid.play();
      // eslint-disable-next-line no-return-assign, no-param-reassign
      setTimeout(() => (vid.controls = "true"), 500);
    }
  };

function Getstarted() {
  const { data, isLoading, isError, error } = useQuery("getstarted", getStartedData);

  console.log(data);
  if (isLoading) {
    return (
      <CircularProgress
        size={100}
        sx={{
          color: "#262262",
          position: "absolute",
          top: "50%",
          left: "50%",
          marginTop: "-12px",
          marginLeft: "-12px",
        }}
      />
    );
  }
  if (isError) {
    return <div>Error! {error.message}</div>;
  }
  return (
    <ThemeProvider theme={theme}>
      <Box className="b-listing-container">
        {/*  Statrt getStarted Section */}
        <Container>
          <Box sx={{ paddingLeft: "91px", paddingRight: "91px", paddingTop: "50px" }}>
            <Grid item md={12} xs={12} pb={2.5}>
              <Heading
                titleStyle="b-listing-container__main-heading"
                variant="span"
                title={data.data[0]?.title}
              />
            </Grid>

            <Grid item md={12} xs={12} mt={2.5} pb={2.5}>
              <Heading
                titleStyle="b-listing-container__sub-heading"
                variant="span"
                title={data.data[0]?.banner_title}
              />
            </Grid>

            <Grid item md={12} xs={12} pb={2}>
              {parser(data.data[0]?.field_body)}
            </Grid>

            <Grid item md={12} xs={12}>
              {parser(data.data[0]?.field_second_body)}
            </Grid>
          </Box>
        </Container>
        {/*  end getStarted Section */}
        {/* Start Video Section */}
        {/* <Grid
          item
          md={12}
          xs={12}
          pb={7.5}
          pt={3.75}
          alignItems="center"
          className="b-listing-container__grid-center">
          {parser(data.data[0]?.banner_video)}
        </Grid> */}
        <Grid
            item
            md={12}
            xs={12}
            pb={7.5}
            pt={3.75}
            alignItems="center"
            className="b-listing-container__grid-center"
            >
            <video
                height="320"
                onClick={(e) => playVid(e.target)}
                poster={data.data[0]?.banner_video_poster}
                preload="auto"
                src={data.data[0]?.banner_video_source}
            >
                <track kind="captions" srcLang="en" label="English" />
                Your browser does not support the video tag.
            </video>
        </Grid>

        {/* end Video Section */}

        <Box>
          <Box className="b-listing-container__bluebox__boxcolor">
            <Grid container px={15} py={7.5}>
              <Grid item md={12}>
                <GetStartedDetailDescription
                  image={data.data[0]?.top_image}
                  title={parser(data.data[0]?.top_title)}
                  descriptionStyle=" b-listing-container__second-para-main"
                  description={parser(data.data[0]?.top_body)}
                  variant="body1"
                />
              </Grid>

              <Grid item md={12} sx={{ marginTop: 7.5 }}>
                <GetStartedDetailDescription
                  image={data.data[0]?.mid_image}
                  title={data.data[0]?.mid_title}
                  description={data.data[0]?.mid_body}
                  rowDirection="row-reverse"
                  descriptionStyle=" b-listing-container__second-para-main"
                  variant="body1"
                />
              </Grid>

              <Grid item md={12} sx={{ marginTop: 7.5 }}>
                <GetStartedDetailDescription
                  image={data.data[0]?.bottom_image}
                  title={data.data[0]?.bottom_title}
                  description={data.data[0]?.bottom_body}
                  descriptionStyle=" b-listing-container__second-para-main"
                  variant="body1"
                />
              </Grid>
            </Grid>
          </Box>
        </Box>

        {/* statrt ExpectFinishSection  + footer */}
        <Container className="b-listing-container__secondbox" sx={{ marginBottom: "0px" }}>
          <Grid container>
            <Grid item md={7} xs={12} pb={7} pr={7.5} pt={7.25}>
              <Heading
                titleStyle="b-listing-container__secondbox__When-Can-I-Expect-My"
                variant="body"
                title={data.data[0]?.section_title}
              />

              <Description
                descriptionStyle=" b-listing-container__secondbox__font-set-one"
                variant="body1"
                description={data.data[0]?.section_body_top}
              />

              <Description
                descriptionStyle=" b-listing-container__secondbox__font-set-two"
                variant="body1"
                description={data.data[0]?.section_body_mid}
              />

              <Description
                descriptionStyle=" b-listing-container__secondbox__font-set-two"
                variant="body1"
                description={data.data[0]?.section_body_bottom}
              />
            </Grid>

            <Grid item md={5} xs={12} pt={7.5}>
              <Card
                className="b-listing-container__secondbox__Image_4_Path "
                sx={{
                  boxShadow: "none",
                  backgroundColor: "transparent",
                }}>
                <CardMedia component="img" image={data.data[0]?.section_image} alt="All of Us" />
              </Card>
            </Grid>
          </Grid>
        </Container>
        <Box>
          <Box className="b-listing-container__bluebox__boxcolor">
            <Grid container px={15} py={7.5}>
              <Grid item md={12} sx={{ marginTop: 0 }}>
                <GetStartedFAQs
                  description={parser(data.data[0]?.faq_body)}
                  image={data.data[0]?.faq_image}
                />
              </Grid>
            </Grid>
          </Box>
        </Box>
        <Grid sx={{ paddingBottom: 2, paddingTop: 5.8 }}>
          <Divider sx={{ width: "100%", height: "1px" }} />
          <Footer status />
        </Grid>
      </Box>
    </ThemeProvider>
  );
}

export default Getstarted;

import { API_URL } from "./endpoints";

export async function getStatusAssets() {
  const { accessToken } = JSON.parse(localStorage.getItem("okta-token-storage"));
  //   console.log("here you go", accessToken);
  const headers = { Authorization: `Bearer ${accessToken?.accessToken}` };
  const response = await fetch(`${API_URL}/api/rest-endpoint/get/status/`, { headers });
  if (response.ok) {
    return response.json();
  }
  return false;
}

export async function getRequestTypesAssets() {
  const { accessToken } = JSON.parse(localStorage.getItem("okta-token-storage"));
  const headers = { Authorization: `Bearer ${accessToken?.accessToken}` };
  const response = await fetch(`${API_URL}/api/rest-endpoint/get/asset/types/`, { headers });
  if (response.ok) {
    return response.json();
  }
  return false;
}

export async function getApprovalLevels() {
  const { accessToken } = JSON.parse(localStorage.getItem("okta-token-storage"));
  const headers = { Authorization: `Bearer ${accessToken?.accessToken}` };
  const response = await fetch(`${API_URL}/api/rest-endpoint/get/asset/approval_level`, {
    headers,
  });
  if (response.ok) {
    return response.json();
  }
  return false;
}

export async function getAffiliationsAssets(userId) {
  const { accessToken } = JSON.parse(localStorage.getItem("okta-token-storage"));
  const headers = { Authorization: `Bearer ${accessToken?.accessToken}` };
  const response = await fetch(`${API_URL}/api/rest-endpoint/get/custom/permissions/${userId}`, {
    headers,
  });
  if (response) {
    return response.json();
  }
  return false;
}

export async function getRequesterAssets() {
  const { accessToken } = JSON.parse(localStorage.getItem("okta-token-storage"));
  const headers = { Authorization: `Bearer ${accessToken?.accessToken}` };
  const response = await fetch(`${API_URL}/api/rest-endpoint/get/requester`, { headers });
  if (response.ok) {
    return response.json();
  }
  return false;
}

export async function getAssetsLegends(userId) {
  const { accessToken } = JSON.parse(localStorage.getItem("okta-token-storage"));
  const headers = { Authorization: `Bearer ${accessToken?.accessToken}` };
  const response = await fetch(`${API_URL}/api/rest-endpoint/get/legends/${userId}`, { headers });
  if (response.ok) {
    return response.json();
  }
  return false;
}

export async function getStartedData(userId) {
  const { accessToken } = JSON.parse(localStorage.getItem("okta-token-storage"));
  const headers = { Authorization: `Bearer ${accessToken?.accessToken}` };
  const response = await fetch(`${API_URL}/api/rest-endpoint/get/started/page/${userId}`, {
    headers,
  });
  if (response.ok) {
    return response.json();
  }
  return false;
}

export async function requestSOP(userId) {
  const { accessToken } = JSON.parse(localStorage.getItem("okta-token-storage"));
  const headers = { Authorization: `Bearer ${accessToken?.accessToken}` };
  const response = await fetch(`${API_URL}/api/rest-endpoint/get/request-sop/page/${userId}`, {
    headers,
  });
  if (response.ok) {
    return response.json();
  }
  return false;
}

export async function getActiveAssetsByUser(dataObj) {
  const { accessToken } = JSON.parse(localStorage.getItem("okta-token-storage"));
  const headers = { Authorization: `Bearer ${accessToken?.accessToken}` };
  const url = `${API_URL}/api/rest-endpoint/get/assets`;

  const { statusIds, requestTypes, partners, requester, sort, userId, approvalLevels } = dataObj;
//   console.log("getActiveAssetsByUser dataObj", dataObj);
  const requestOptions = {
    method: "POST",
    body: JSON.stringify({
      statusIds,
      requestTypes,
      partners,
      requester,
      sort,
      userId,
      approvalLevels,
    }),
    headers,
  };

  const response = await fetch(url, requestOptions);
  if (response.ok) {
    const result = await response.json();
    // console.log("getActiveAssetsByUser response", result);
    return result;
  }
  return false;
}

export async function getActiveAssetsFilterCount(dataObj) {
  const { accessToken } = JSON.parse(localStorage.getItem("okta-token-storage"));
  const headers = { Authorization: `Bearer ${accessToken?.accessToken}` };
  const url = `${API_URL}/api/rest-endpoint/get/assets-count`;

  const { statusIds, requestTypes, partners, requester, sort, userId, approvalLevels } = dataObj;
  const requestOptions = {
    method: "POST",
    body: JSON.stringify({
      statusIds,
      requestTypes,
      partners,
      requester,
      sort,
      userId,
      approvalLevels,
    }),
    headers,
  };

  const response = await fetch(url, requestOptions);
  if (response.ok) {
    const result = await response.json();
    return result;
  }
  return false;
}

export async function getCompletedAssetsFilterCount(dataObj) {
  const { accessToken } = JSON.parse(localStorage.getItem("okta-token-storage"));
  const headers = { Authorization: `Bearer ${accessToken?.accessToken}` };
  const url = `${API_URL}/api/rest-endpoint/get-completed-assets-count`;

  const { statusIds, requestTypes, partners, requester, sort, userId, approvalLevels } = dataObj;
  const requestOptions = {
    method: "POST",
    body: JSON.stringify({
      statusIds,
      requestTypes,
      partners,
      requester,
      sort,
      userId,
      approvalLevels,
    }),
    headers,
  };

  const response = await fetch(url, requestOptions);
  if (response.ok) {
    const result = await response.json();
    return result;
  }
  return false;
}

export async function getCompeletedAssetsByUser(dataObj) {
  const { accessToken } = JSON.parse(localStorage.getItem("okta-token-storage"));
  const headers = { Authorization: `Bearer ${accessToken?.accessToken}` };
  const url = `${API_URL}/api/rest-endpoint/get-completed-assets`;

  const { statusIds, requestTypes, partners, requester, sort, userId, approvalLevels } = dataObj;
  const requestOptions = {
    method: "POST",
    body: JSON.stringify({
      statusIds,
      requestTypes,
      partners,
      requester,
      sort,
      userId,
      approvalLevels,
    }),
    headers,
  };

  const response = await fetch(url, requestOptions);
  if (response.ok) {
    const result = await response.json();
    return result;
  }
  return false;
}
export const getCopyLinkDetail = async (id) => {
  const { accessToken } = JSON.parse(localStorage.getItem("okta-token-storage"));
  const headers = { Authorization: `Bearer ${accessToken?.accessToken}` };
  const url = `${API_URL}/api/rest-endpoint/get/asset/detail/${id}`;
  const response = await fetch(url, { headers });
  if (response.ok) {
    // console.log("getCopyLink JSON: ", response);
    return response.json();
  }
  return false;
};
